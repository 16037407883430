<template>
  <div
    class="grid md:grid-cols-2 mt-3 gap-3"
  >
    <CCard v-if="placementNotes && placementNotes.length > 0" spacing>
      <CTypo tstyle="title3">{{ $t('placementNote.general') }}</CTypo>
      <p class="prose mt-3" v-html="placementNotes" />
    </CCard>
    <CCard>
      <CDescriptionList
        :title="$t('products.container.sizeInformation.containerSizeInformation')"
      >
        <CDescriptionListItem
          v-for="size in containerSize"
          :key="size[0]"
          :label="size[0]"
        >
          {{ parseSize(size[1]) }}
        </CDescriptionListItem>
        <CDescriptionListItem
          :label="$t('products.container.sizeInformation.vehicleSizeInformation')"
          highlight
        >
          <span />
        </CDescriptionListItem>
        <CDescriptionListItem
          v-for="size in vehicleSize"
          :key="`vehicle-${size[0]}`"
          :label="size[0]"
        >
          <span v-if="size[2] !== 'weight'" class="calt">{{ parseSize(size[1]) }}</span>
          <span v-else>{{ parseWeight(size[1]) }}</span>
        </CDescriptionListItem>
      </CDescriptionList>
    </CCard>
  </div>
</template>

<script>
export default {
  props: {
    placementNotes: {
      type: String,
      default: null,
    },
    selectedContainer: {
      type: Object,
      default: null,
    },
  },

  computed: {
    containerSize() {
      if (this.selectedContainer) {
        return [
          [this.$t('sizes.length'), this.selectedContainer.length],
          [this.$t('sizes.width'), this.selectedContainer.width],
        ];
      }
      return [];
    },
    vehicleSize() {
      if (this.selectedContainer) {
        return [
          [this.$t('sizes.overallLength'), this.selectedContainer.vehicleOverallLength],
          [this.$t('sizes.passageWidth'), this.selectedContainer.vehiclePassageWidth],
          [
            this.$t('sizes.maneuveringArea'),
            [
              this.selectedContainer.vehicleManeuveringAreaLength,
              this.selectedContainer.vehicleManeuveringAreaWidth,
            ],
          ],
          [this.$t('sizes.totalWeight'), this.selectedContainer.vehicleTotalWeight, 'weight'],
        ];
      }
      return [];
    },
  },

  methods: {
    parseSize(size) {
      if (Array.isArray(size)) {
        return `${(size[0] / 100).toFixed(2).replace('.', ',')}m x ${(size[1] / 100)
          .toFixed(2)
          .replace('.', ',')}m`;
      }
      if (size) {
        return `${(size / 100).toFixed(2).replace('.', ',')}m`;
      }
      return null;
    },
    parseWeight(weight) {
      if (weight) {
        return `${(weight / 1000).toFixed(2).replace('.', ',')}t`;
      }
      return null;
    },
  },
};
</script>
