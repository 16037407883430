<template>
  <div>
    <div class="grid grid-cols-12 gap-3">
      <MerchantWasteImage class="col-span-12 lg:col-span-7" />
      <OrderCalculation
        v-if="selectedContainer"
        class="col-span-12 lg:col-span-5 h-full"
        @onSubmit="onSubmit"
      />
      <SelectContainer class="col-span-12 lg:col-span-7" />
      <PermittedTypeOfWaste class="col-span-12 lg:col-span-5" />
    </div>
    <NotesAndSize
      :placement-notes="shop.generalPlacementNotes"
      :selected-container="selectedContainer"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { GET_CONTAINER_SELECTION } from '@/store/actionTypes';
import {
  DELIVERY_DATE,
  PICKUP_DATE,
  SELECTED_CONTAINER,
  SELECTED_DELIVERY_AREA,
  SELECTED_MERCHANT_WASTE,
  SHOP,
  STOREFRONT_CONTAINER_SELECTION_LOADING,
  THIS_USER,
} from '@/store/gettersTypes';
import { SET_SELECTED_DELIVERY_AREA, SET_SELECTED_MERCHANT_WASTE } from '@/store/mutationTypes';
import { OrderCalculation } from '@/ui/components';
import NotesAndSize from '@/ui/components/SelectContainer/NotesAndSizes.vue';
import MerchantWasteImage from '../components/SelectContainer/MerchantWasteImage.vue';
import SelectContainer from '../components/SelectContainer/SelectContainer.vue';
import PermittedTypeOfWaste from '../components/SelectContainer/PermittedTypeOfWaste.vue';

export default {
  components: {
    MerchantWasteImage,
    SelectContainer,
    PermittedTypeOfWaste,
    OrderCalculation,
    NotesAndSize,
  },

  computed: {
    ...mapGetters([
      SELECTED_MERCHANT_WASTE,
      SELECTED_DELIVERY_AREA,
      STOREFRONT_CONTAINER_SELECTION_LOADING,
      SELECTED_CONTAINER,
      THIS_USER,
      DELIVERY_DATE,
      PICKUP_DATE,
      SHOP,
    ]),
  },

  async created() {
    if (
      this.$route.query &&
      this.$route.query.merchantWasteId &&
      parseInt(this.$route.query.merchantWasteId, 10) &&
      this.$route.query.deliveryAreaId &&
      parseInt(this.$route.query.deliveryAreaId, 10)
    ) {
      this.setSelectedMerchantWaste(parseInt(this.$route.query.merchantWasteId, 10));
      this.setSelectedDeliveryArea(parseInt(this.$route.query.deliveryAreaId, 10));
    }

    if (!this.selectedMerchantWaste || !this.selectedDeliveryArea) {
      return this.$router.push('/');
    }

    return this.getContainerSelection();
  },

  methods: {
    ...mapActions([GET_CONTAINER_SELECTION]),
    ...mapMutations([SET_SELECTED_MERCHANT_WASTE, SET_SELECTED_DELIVERY_AREA]),
    onSubmit() {
      const path = this.thisUser ? '/address' : '/login';

      this.$router.push({
        path,
        query: {
          merchantWasteId: this.selectedMerchantWaste.id,
          deliveryAreaId: this.selectedDeliveryArea.id,
          productId: this.selectedContainer.id,
          deliveryDate: this.deliveryDate.toString(),
          pickupDate: this.pickupDate.toString(),
        },
      });
    },
  },
};
</script>
