<template>
  <div class="grid grid-cols-1 sm:grid-cols-2 gap-3">
    <CLoading v-if="storefrontContainerSelectionLoading" class="col-span-2" />
    <CSelectBox
      v-else
      v-for="matchingContainer in matchingContainers"
      :key="matchingContainer.id"
      v-model="containerSelection"
      :native-value="matchingContainer.id"
      :name="matchingContainer.type"
      required
    >
      <div class="flex items-center">
        <CContainerImage
          :type="matchingContainer.type"
          :flap="matchingContainer.flap"
          :cover="matchingContainer.cover"
          :doors="matchingContainer.doors"
          :size="matchingContainer.size"
        />
        <div class="ml-2">
          <CTypo tstyle="title3">
            {{ $t(`products.container.types.${matchingContainer.type}`) }}
            {{ matchingContainer.size.toLocaleString('de-DE') }}m³
          </CTypo>
          <CContainerOpeningType
            :container="matchingContainer"
            class="text-xs font-normal text-gray-700"
          />
          <CTypo tstyle="subtitle">
            {{ $n(parsePrice(matchingContainer.priceCalculation.totalPrice), 'currency') }}
          </CTypo>
        </div>
      </div>

      <CAlert
        v-if="matchingContainer.isOverbooked"
        class="mt-3 w-full"
        variant="danger"
      >
        {{ $t('containerIsOverbooked') }}
      </CAlert>
    </CSelectBox>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { toDecimalPriceInclTax } from '@contimo/api/src/utils/integerPriceParser';
import {
  MATCHTING_CONTAINERS,
  SELECTED_CONTAINER,
  STOREFRONT_CONTAINER_SELECTION_LOADING,
} from '@/store/gettersTypes';
import { SET_SELECTED_CONTAINER } from '@/store/mutationTypes';

export default {
  computed: {
    ...mapGetters([
      MATCHTING_CONTAINERS,
      SELECTED_CONTAINER,
      STOREFRONT_CONTAINER_SELECTION_LOADING,
    ]),
    containerSelection: {
      get() {
        return this.selectedContainer.id;
      },
      set(value) {
        this.setSelectedContainer(value);
      },
    },
  },

  methods: {
    ...mapMutations([SET_SELECTED_CONTAINER]),
    parsePrice(price) {
      return toDecimalPriceInclTax(price, 19);
    },
  },
};
</script>
