<template>
  <CCard spacing>
    <div class="flex items-center">
      <CIcon
        name="check-circle"
        class="text-green-600 mr-2"
      />
      <CTypo tstyle="headline">{{ $t('merchantWastes.accepted') }}</CTypo>
    </div>
    <div>
      <span v-for="acceptedWaste, i in selectedMerchantWaste.accepted" :key="acceptedWaste">
        {{ acceptedWaste }}{{ i + 1 !== selectedMerchantWaste.accepted.length ? ',' : '' }}
      </span>
    </div>
    <div class="flex items-center mt-2">
      <CIcon
        name="x-circle"
        class="text-red-600 mr-2"
      />
      <CTypo tstyle="headline">{{ $t('merchantWastes.notAccepted') }}</CTypo>
    </div>
    <div>
      <span 
        v-for="notAcceptedWaste, i in selectedMerchantWaste.notAccepted" 
        :key="notAcceptedWaste"
      >
        {{ notAcceptedWaste }}{{ i + 1 !== selectedMerchantWaste.notAccepted.length ? ',' : '' }}
      </span>
    </div>
  </CCard>
</template>

<script>
import { mapGetters } from 'vuex';
import { SELECTED_MERCHANT_WASTE } from '@/store/gettersTypes';

export default {
  computed: {
    ...mapGetters([SELECTED_MERCHANT_WASTE]),
  },
};
</script>