<template>
  <CCard
    class="relative overflow-hidden w-full bg-white rounded-md
    h-[450px] flex justify-center items-center">
    <div class="flex absolute top-0 left-0">
      <CButton size="small" class="font-semibold top-0 left-0 m-5 z-2" to="/">
        {{ $t('changeWasteType') }}
      </CButton>
    </div>
    <CSvgImage :src="selectedMerchantWasteImage" image-class="w-full" svg-class="w-full max-w-[400px]" />
  </CCard>
</template>

<script>
import { mapGetters } from 'vuex';
import { SELECTED_MERCHANT_WASTE } from '@/store/gettersTypes';
import { getUploadAssetUrl } from '@/utils/assets';

export default {
  computed: {
    ...mapGetters([SELECTED_MERCHANT_WASTE]),
    selectedMerchantWasteImage() {
      return getUploadAssetUrl(
        this.selectedMerchantWaste.image || this.selectedMerchantWaste.platformWaste.image,
      );
    },
  },
};
</script>
